import httpClient from "./httpClient";

const fetchSourcesAudienceList = (
  hash: string,
  locale: string,
  offset: number,
  limit: number
) => {
  const params = { h: hash, locale, offset, limit };
  return httpClient.get("/audience-list", { params });
};

export { fetchSourcesAudienceList };
