
import Vue from "vue";
import { mapActions } from "vuex";
import DonutChart from "./charts/DonutChart.vue";
import AudienceTable from "./AudienceTable.vue";
import { SourcesAudienceList } from "@/models/SourcesAudienceList";
import { ChartData } from "@/models/ChartData";

export default Vue.extend({
  name: "SourcesAudienceCard",

  components: {
    DonutChart,
    AudienceTable
  },

  props: {
    audience: {
      type: Object as () => ChartData,
      required: false
    },

    sourcesAudienceList: {
      type: Object as () => SourcesAudienceList,
      required: true
    }
  },

  methods: {
    ...mapActions("Sourcesaudiencelist", ["getSourcesAudienceList"]),

    async getMoreSourcesAudience() {
      const payload = {
        hash: this.$route.query.h,
        locale: this.$route.query.locale,
        source: this.$route.query.source
      };

      await this.getSourcesAudienceList(payload);
    }
  }
});
