import httpClient from "./httpClient";

const fetchMoreNews = (
  hash: string,
  locale: string,
  offset: number,
  limit: number
) => {
  const params = { h: hash, locale, offset, limit };
  return httpClient.get("/news", { params });
};

export { fetchMoreNews };
