import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#282E37",
        secondary: "#F8AC81",
        accent: "#2E3944",
        info: "#036FF4"
      }
    }
  }
});
