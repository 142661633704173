
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import NewsContainer from "./NewsContainer.vue";

export default Vue.extend({
  name: "NewsCard",

  components: {
    NewsContainer
  },

  computed: {
    ...mapState("News", ["news"])
  },

  methods: {
    ...mapActions("News", ["getMoreNews"]),

    async fetchMoreNews() {
      const payload = {
        hash: this.$route.query.h,
        locale: this.$route.query.locale
      };

      await this.getMoreNews(payload);
    }
  }
});
