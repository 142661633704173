
import Vue from "vue";
import SummaryCard from "./SummaryCard.vue";

import { mapState } from "vuex";

export default Vue.extend({
  name: "ReportResume",

  components: {
    SummaryCard
  },

  data() {
    return {};
  },

  computed: {
    ...mapState("Smartreport", ["smartReport"])
  },

  methods: {
    share() {
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = window.location.href;
      dummy.select();
      try {
        document.execCommand("copy");
        alert("Link para compartilhar foi copiado!");
      } catch (err) {
        alert("Oops, unable to copy");
      } finally {
        document.body.removeChild(dummy);
      }
    }
  }
});
