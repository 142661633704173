
import { ChartData } from "@/models/ChartData";
import Vue from "vue";
import DonutChart from "./charts/DonutChart.vue";

export default Vue.extend({
  name: "ValuationCard",
  components: {
    DonutChart
  },

  props: {
    valuation: {
      type: Object as () => ChartData,
      required: false
    }
  }
});
