
import { ChartData } from "@/models/ChartData";
import Vue from "vue";
import VerticalBarChart from "./charts/VerticalBarChart.vue";

export default Vue.extend({
  name: "NewsBySourceTypeCard",

  components: {
    VerticalBarChart
  },

  props: {
    newsBySourceType: {
      type: Object as () => ChartData,
      required: false
    }
  }
});
