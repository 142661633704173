
import { NewsInfo } from "@/models/NewsInfo";
import Vue from "vue";
export default Vue.extend({
  name: "NewsContainer",

  data() {
    return {
      dialog: false,
      sortedNews: this.$props.news.sort((a: NewsInfo, b: NewsInfo) => {
        const dataA = a.publishedDate || a.crawledDate;
        const dataB = b.publishedDate || b.crawledDate;
        return new Date(dataA).getTime() - new Date(dataB).getTime();
      })
    };
  },

  computed: {
    similarNews: function() {
      const [, ...similarNews] = this.news;
      return similarNews;
    },

    formattedDate: function() {
      const date = new Date(this.$data.sortedNews[0].publishedDate);
      const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      const month =
        date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      const year = date.getFullYear();
      const hours =
        date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
      const mins =
        date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
      return `${day}/${month}/${year} ${hours}h${mins}`;
    },

    formattedValuation: function(): string {
      const locale = this.$route.query.locale as string;
      return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: locale === "pt" ? "BRL" : "USD"
      }).format(this.$data.sortedNews[0].valuation);
    }
  },
  props: {
    news: {
      type: Array as () => Array<NewsInfo>,
      required: true
    },

    isSimilarNews: {
      type: Boolean,
      required: false,
      default: false
    }
  }
});
