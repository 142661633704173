
import { ChartData } from "@/models/ChartData";
import Vue from "vue";
import HorizontalBarChart from "./charts/HorizontalBarChart.vue";

export default Vue.extend({
  name: "AudienceByStateCard",

  components: {
    HorizontalBarChart
  },

  props: {
    stateAudience: {
      type: Object as () => ChartData,
      required: false
    }
  }
});
