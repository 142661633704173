
import Vue from "vue";

export default Vue.extend({
  name: "SummaryCard",

  props: {
    title: {
      type: String,
      required: true
    },

    text: {
      type: String,
      required: true
    }
  }
});
