
import Vue from "vue";
import ApexCharts, { ApexOptions } from "apexcharts";
import { ChartData } from "@/models/ChartData";

export default Vue.extend({
  name: "HorizontalBarChart",

  data(): DataObject {
    return {
      chart: null
    };
  },

  props: {
    data: {
      type: Object as () => ChartData,
      required: true
    },

    parentId: {
      type: String,
      required: true
    },

    color: {
      type: String,
      required: true
    }
  },

  mounted() {
    const chartOptions: ApexOptions = {
      chart: {
        type: "bar",
        toolbar: {
          show: false
        }
      },
      series: [
        {
          data: this.data.values
        }
      ],
      xaxis: {
        categories: this.data.labels,
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: true
        },
        labels: {
          show: false,
          style: {
            colors: "#43576B",
            fontSize: "14px",
            fontFamily: "Roboto, sans-serif",
            fontWeight: 400
          }
        }
      },
      yaxis: {
        axisTicks: {
          show: false
        },
        labels: {
          show: true,
          style: {
            colors: "#43576B",
            fontSize: "14px",
            fontFamily: "Roboto, sans-serif",
            fontWeight: 700
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "60%",
          dataLabels: {
            position: "top",
            maxItems: 1000,
            hideOverflowingLabels: false
          }
        }
      },
      tooltip: {
        enabled: false
      },
      grid: {
        show: false
      },
      dataLabels: {
        offsetX: 30,
        style: {
          colors: ["#43576B"]
        }
      },
      colors: [this.color],
      states: {
        active: {
          filter: {
            type: "none"
          }
        }
      }
    };

    const chart = new ApexCharts(
      document.querySelector(`#${this.parentId} #apexchart`),
      chartOptions
    );

    chart.render();
  }
});

interface DataObject {
  chart: null | ApexCharts;
}
