
import Vue from "vue";
import ApexCharts, { ApexOptions } from "apexcharts";
import { ChartData } from "@/models/ChartData";

export default Vue.extend({
  name: "LineChart",

  data(): DataObject {
    return {
      chart: null
    };
  },

  props: {
    data: {
      type: Object as () => ChartData,
      required: true
    },

    parentId: {
      type: String,
      required: true
    }
  },

  mounted() {
    const chartOptions: ApexOptions = {
      series: [
        {
          data: this.data.values,
          color: "#036FF4"
        }
      ],
      chart: {
        type: "area",
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2,
        curve: "smooth",
        colors: ["#036FF4"]
      },
      xaxis: {
        type: "category",
        categories: this.data.labels
      },
      yaxis: {
        show: false
      },
      grid: {
        show: true,
        strokeDashArray: 2,
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100]
        }
      },
      tooltip: {
        x: {
          show: false
        },
        y: {
          formatter: undefined,
          title: {
            formatter: seriesName => "Notícias capturadas:"
          }
        }
      }
    };

    this.chart = new ApexCharts(
      document.querySelector(`#${this.parentId} #apexchart`),
      chartOptions
    );

    this.chart.render();
  }
});

interface DataObject {
  chart: null | ApexCharts;
}
