import axios, { AxiosResponse, AxiosError } from "axios";
import { camelizeKeys } from "humps";
import router from "@/router";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_SMARTREPORT_API_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json"
  }
});

// interceptor to catch errors
const errorInterceptor = (error: AxiosError) => {
  // all the error responses
  if (error.response) {
    switch (error.response.status) {
      case 400:
        console.error(error.response.status, error.message);
        alert("Nothing to display");
        break;

      case 401:
        localStorage.removeItem("token");
        router.push("/login");
        break;

      default:
        console.error(error.response.status, error.message);
        alert("Server Error");
    }
  }
  return Promise.reject(error);
};
const snakeCaseToCamelCase = (response: AxiosResponse) => {
  if (response.data) {
    response.data = camelizeKeys(response.data);
  }
  return response;
};

// Interceptor for responses
const responseInterceptor = (response: AxiosResponse) => {
  response = snakeCaseToCamelCase(response);
  switch (response.status) {
    case 200:
      // yay!
      break;
    // any other cases
    default:
    // default case
  }

  return response;
};

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export default httpClient;
