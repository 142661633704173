
import { ChartData } from "@/models/ChartData";
import Vue from "vue";
import LineChart from "./charts/LineChart.vue";

export default Vue.extend({
  name: "NewsHistoryCard",

  components: {
    LineChart
  },

  props: {
    newsHistory: {
      type: Object as () => ChartData,
      required: false
    }
  }
});
