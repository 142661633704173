
import Vue from "vue";
import { AudienceTableValues } from "@/models/AudienceTableValues";

export default Vue.extend({
  name: "AudienceTable",

  props: {
    tableValues: {
      type: Array as () => Array<AudienceTableValues>,
      required: true
    }
  }
});
