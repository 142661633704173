
import { ChartData } from "@/models/ChartData";
import Vue from "vue";
import HorizontalBarChart from "./charts/HorizontalBarChart.vue";

export default Vue.extend({
  name: "GlobalAudienceCard",

  components: {
    HorizontalBarChart
  },

  props: {
    globalAudience: {
      type: Object as () => ChartData,
      required: false
    }
  }
});
