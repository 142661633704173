
import Vue from "vue";
import ReportResume from "../components/ReportResume.vue";
import NewsCard from "../components/NewsCard.vue";
import SourcesAudienceCard from "../components/SourcesAudienceCard.vue";
import ValuationCard from "../components/ValuationCard.vue";
import GlobalAudienceCard from "../components/GlobalAudienceCard.vue";
import AudienceByStateCard from "../components/AudienceByStateCard.vue";
import NewsBySourceTypeCard from "../components/NewsBySourceTypeCard.vue";
import NewsHistoryCard from "../components/NewsHistoryCard.vue";

import { mapActions, mapState } from "vuex";

interface Chart {
  title: string;
  chartUrl: string | null;
}

export default Vue.extend({
  name: "App",

  components: {
    ReportResume,
    NewsCard,
    SourcesAudienceCard,
    ValuationCard,
    GlobalAudienceCard,
    AudienceByStateCard,
    NewsBySourceTypeCard,
    NewsHistoryCard
  },

  data: () => ({
    chartList: new Array<Chart>()
  }),

  computed: {
    ...mapState("News", ["news"]),
    ...mapState("Smartreport", ["smartReport"])
  },

  async mounted() {
    this.$root.$i18n.locale = this.$route.query.locale as string;

    const payload = {
      hash: this.$route.query.h,
      locale: this.$route.query.locale,
      source: this.$route.query.source
    };

    await this.getSmartReport(payload);

    await this.getMoreNews({
      hash: this.$route.query.h,
      locale: this.$route.query.locale
    });
  },

  methods: {
    ...mapActions("Smartreport", ["getSmartReport"]),
    ...mapActions("News", ["getMoreNews"])
  }
});
