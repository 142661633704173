
import Vue from "vue";
import ApexCharts, { ApexOptions } from "apexcharts";
import { ChartData } from "@/models/ChartData";

export default Vue.extend({
  name: "VerticalBarChart",

  data(): DataObject {
    return {
      chart: null,
      labels: []
    };
  },

  props: {
    data: {
      type: Object as () => ChartData,
      required: true
    },

    parentId: {
      type: String,
      required: true
    },

    color: {
      type: String,
      required: true
    }
  },

  mounted() {
    this.formatLabels();

    const chartOptions: ApexOptions = {
      chart: {
        type: "bar",
        toolbar: {
          show: false
        }
      },
      series: [
        {
          data: this.data.values
        }
      ],
      xaxis: {
        categories: this.labels,
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: true
        },
        labels: {
          show: true,
          rotate: 0,
          showDuplicates: true,
          hideOverlappingLabels: false,
          style: {
            colors: "#43576B",
            fontSize: "14px",
            fontFamily: "Roboto, sans-serif",
            fontWeight: 400
          }
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5
            }
          }
        }
      },
      yaxis: {
        axisTicks: {
          show: false
        },
        labels: {
          show: true,
          style: {
            colors: "#43576B",
            fontSize: "14px",
            fontFamily: "Roboto, sans-serif",
            fontWeight: 400
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          barHeight: "60%",
          dataLabels: {
            position: "top",
            maxItems: 1000,
            hideOverflowingLabels: false
          },
          columnWidth: "16px"
        }
      },
      tooltip: {
        enabled: false
      },
      grid: {
        show: true,
        strokeDashArray: 2
      },
      dataLabels: {
        offsetY: -18,
        style: {
          colors: ["#43576B"]
        }
      },
      colors: [this.color],
      states: {
        active: {
          filter: {
            type: "none"
          }
        }
      }
    };

    const chart = new ApexCharts(
      document.querySelector(`#${this.parentId} #apexchart`),
      chartOptions
    );

    chart.render();
  },

  methods: {
    formatLabels() {
      for (const label of this.data.labels.values()) {
        this.labels.push(label.split(" "));
      }
    }
  }
});

interface DataObject {
  chart: null | ApexCharts;
  labels: Array<Array<string>>;
}
