import { fetchMoreNews } from "@/api/news.api";

import { MutationTree, ActionTree, ActionContext } from "vuex";
import { News } from "@/models/News";

interface State {
  offset: number;
  limit: number;
  news: News | null;
}

const state: State = {
  offset: 0,
  limit: 6,
  news: null
};

type Mutations<S = State> = {
  SET_NEWS(state: S, payload: News): void;
  INCREMENT_OFFSET(state: S): void;
};

const mutations: MutationTree<State> & Mutations = {
  SET_NEWS(state, payload: News) {
    if (state.news) {
      state.news.news = state.news.news.concat(payload.news);
    } else {
      state.news = payload;
    }
  },

  INCREMENT_OFFSET(state) {
    state.offset += state.limit;
  }
};

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, "commit">;

const actions: ActionTree<State, State> = {
  async getMoreNews({ state, commit }, payload) {
    try {
      const response = await fetchMoreNews(
        payload.hash,
        payload.locale,
        state.offset,
        state.limit
      );
      commit("SET_NEWS", response.data);
      commit("INCREMENT_OFFSET");
    } catch (error) {
      console.log(error);
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
