import { fetchSmartReport } from "@/api/smartreport.api";

import { MutationTree, ActionTree, ActionContext } from "vuex";
import { SmartReport } from "@/models/SmartReport";
import { SourcesAudienceList } from "@/models/SourcesAudienceList";

interface State {
  smartReport: SmartReport | null;
}

const state: State = {
  smartReport: null
};

type Mutations<S = State> = {
  SET_SMART_REPORT(state: S, payload: SmartReport): void;
};

const mutations: MutationTree<State> & Mutations = {
  SET_SMART_REPORT(state, smartReport: SmartReport) {
    state.smartReport = smartReport;
  },

  SET_SOURCES_AUDIENCE_LIST(state, sourcesAudienceList: SourcesAudienceList) {
    if (state.smartReport?.sourcesAudienceList.tableValues) {
      state.smartReport.sourcesAudienceList.tableValues = state.smartReport.sourcesAudienceList.tableValues.concat(
        sourcesAudienceList.tableValues
      );
    }
  }
};

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, "commit">;

const actions: ActionTree<State, State> = {
  async getSmartReport({ commit }: AugmentedActionContext, payload) {
    try {
      const response = await fetchSmartReport(
        payload.hash,
        payload.locale,
        payload.source
      );
      commit("SET_SMART_REPORT", response.data);
    } catch (error) {
      console.log(error);
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
