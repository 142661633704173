
import Vue from "vue";
import ApexCharts, { ApexOptions } from "apexcharts";
import { ChartData } from "@/models/ChartData";

export default Vue.extend({
  name: "DonutChart",

  data(): DataObject {
    return {
      chart: null
    };
  },

  props: {
    data: {
      type: Object as () => ChartData,
      required: true
    },

    parentId: {
      type: String,
      required: true
    },

    scale: {
      type: Number,
      required: false,
      default: 1
    },

    labelsOffset: {
      type: Number,
      required: false,
      default: 0
    }
  },

  mounted() {
    const chartOptions: ApexOptions = {
      chart: {
        type: "donut",
        height: "280px"
      },
      labels: this.data.labels,
      colors: ["#00478E", "#036FF4", "#83C1FF", "#DDDDDD"],
      series: this.data.values,
      legend: {
        show: false
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          customScale: this.scale,
          dataLabels: {
            offset: this.labelsOffset,
            minAngleToShowLabel: 0
          },
          donut: {
            size: "85%"
          }
        }
      },
      dataLabels: {
        distributed: true,
        style: {
          fontSize: "14px",
          fontFamily: "Roboto, sans-serif",
          fontWeight: "bold",
          colors: ["#2E3944"]
        },
        background: {
          enabled: false,
          dropShadow: {
            enabled: false
          }
        },
        dropShadow: {
          enabled: false
        }
      },
      tooltip: {
        fillSeriesColor: false
      },
      states: {
        active: {
          filter: {
            type: "none"
          }
        }
      }
    };

    this.chart = new ApexCharts(
      document.querySelector(`#${this.parentId} #apexchart`),
      chartOptions
    );

    this.chart.render();
  }
});

interface DataObject {
  chart: null | ApexCharts;
}
