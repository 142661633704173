import { fetchSourcesAudienceList } from "@/api/sourcesaudiencelist.api";

import { MutationTree, ActionTree, ActionContext } from "vuex";

interface State {
  offset: number;
  limit: number;
  total: number;
}

const state: State = {
  offset: 8,
  limit: 8,
  total: 0
};

type Mutations<S = State> = {
  INCREMENT_OFFSET(state: S): void;
};

const mutations: MutationTree<State> & Mutations = {
  INCREMENT_OFFSET(state) {
    state.offset += state.limit;
  }
};

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, "commit">;

const actions: ActionTree<State, State> = {
  async getSourcesAudienceList({ state, commit }, payload) {
    try {
      const response = await fetchSourcesAudienceList(
        payload.hash,
        payload.locale,
        state.offset,
        state.limit
      );
      commit("Smartreport/SET_SOURCES_AUDIENCE_LIST", response.data, {
        root: true
      });
      commit("INCREMENT_OFFSET");
    } catch (error) {
      console.log(error);
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
